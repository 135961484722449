import React from 'react'
import Authereum from 'authereum'
import Web3 from 'web3'
import * as config from './config'
import * as utils from '@authereum/utils'

class App extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      account: null,
      balance: null,
      amount: '0.001',
      personalMessage: '',
      message: '',
      signature: null,
      personalSignature: null,
      recipient: '0x22d491Bde2303f2f43325b2108D26f1eAbA1e32b',
      txHash: null,
      sending: false
    }

    this.init()
  }

  async init () {
    const authereum = new Authereum({
      webUri: config.webUri,
      apiUri: config.apiUri,
      rpcUri: config.rpcUri,
      xsUri: config.xsUri,
      networkName: config.networkName
    })

    this.web3 = new Web3(authereum.getProvider())
    window.web3 = this.web3

    this.refreshAccount()
    this.refreshBalance()

    setInterval(() => {
      this.refreshAccount()
      this.refreshBalance()
    }, 3000)
  }

  render () {
    return (
      <div className='App'>
        {this.renderHeader()}
        {this.state.account ? this.renderLoggedIn() : this.renderLoggedOut()}
      </div>
    )
  }

  renderHeader() {
    return (
      <div style={{
        marginBottom: '2em'
      }}>
        <h1>Demo</h1>
      </div>
    )
  }

  renderLoggedIn () {
    return (
      <div style={{
        marginBottom: '2em'
      }}>
        {this.renderAccountAddress()}
        {this.renderBalance()}
        {this.renderSignMessage()}
        {this.renderSignPersonalMessage()}
        {this.renderSendEth()}

        <button
          className='btn btn-warning'
          onClick={event => {
            event.preventDefault()
            this.web3.currentProvider.disable()
          }}>Sign out of dapp</button>
      </div>
    )
  }

  renderAccountAddress () {
    if (!this.state.account) return null

    return (
      <div style={{
        marginBottom: '1em'
      }}>
        Account address: {this.state.account}
      </div>
    )
  }

  renderBalance() {
    return (
      <div style={{
        marginBottom: '1em'
      }}>
        Balance: Ξ{this.state.balance ? this.state.balance : '-'}
      </div>
    )
  }

  renderSignPersonalMessage () {
    return (
      <div style={{
        marginBottom: '2em'
      }}>
        <div style={{
          marginBottom: '1em'
        }}>
          <label style={
            { display: 'block' }
          }>Sign message using eth.personal.sign</label>
          <div className='input-group mb-3'>
            <input
              className='form-control'
              type='text'
              placeholder='message'
              value={this.state.personalMessage}
              onChange={event => this.setState({ personalMessage: event.target.value })}
            />
            <div className='input-group-append'>
              <button
                className='btn btn-primary'
                onClick={event => {
                  event.preventDefault()
                  this.signPersonalMessage()
                }}>Sign</button>
            </div>
          </div>
        </div>
        {this.state.personalSignature
          ? <div>
            Signature
            <pre>{this.state.personalSignature}</pre>
          </div> : null }
      </div>
    )
  }

  renderSignMessage () {
    return (
      <div style={{
        marginBottom: '2em'
      }}>
        <div style={{
          marginBottom: '1em'
        }}>
          <label style={
            { display: 'block' }
          }>Sign message using eth.sign</label>
          <div className='input-group mb-3'>
            <input
              className='form-control'
              type='text'
              placeholder='message'
              value={this.state.message}
              onChange={event => this.setState({ message: event.target.value })}
            />
            <div className='input-group-append'>
              <button
                className='btn btn-primary'
                onClick={event => {
                  event.preventDefault()
                  this.signMessage()
                }}>Sign</button>
            </div>
          </div>
        </div>
        {this.state.signature
          ? <div>
            Signature
            <pre>{this.state.signature}</pre>
          </div> : null }
      </div>
    )
  }

  renderSendEth () {
    return (
      <div style={{
        marginBottom: '2em'
      }}>
        <div style={{
          marginBottom: '1em'
        }}>
          <label style={
            { display: 'block' }
          }>Send ETH</label>
          <div className='input-group mb-3'>
            <input
              className='form-control'
              type='text'
              placeholder='to address'
              value={this.state.recipient}
              onChange={event => this.setState({ recipient: event.target.value })}
            />
            <input
              className='form-control'
              type='text'
              placeholder='amount'
              style={{
                maxWidth: '8em'
              }}
              value={this.state.amount}
              onChange={event => this.setState({ amount: event.target.value })}
            />
            <div className='input-group-append'>
              <button
                disabled={this.state.sending}
                className='btn btn-primary'
                onClick={event => {
                  this.sendTransaction()
                }}>Send</button>
            </div>
          </div>
        </div>
        {this.state.txHash
          ? <div>
            Transaction
            <a href={`http://etherscan.io/tx/${this.state.txHash}`}>{this.state.txHash}</a>
          </div> : null }
      </div>
    )
  }

  renderLoggedOut () {
    return (
      <div>
        <button
          className='btn btn-primary btn-lg'
          onClick={event => {
            event.preventDefault()
            this.web3.currentProvider.enable()
          }}>Sign in with Authereum</button>
      </div>
    )
  }

  async loginWithInjectedWeb3 () {
    if (window.ethereum) {
      await window.ethereum.enable()
    } else {
      console.error('Ethereum not found on window')
      return
    }

    if (window.web3) {
      if (!window.web3.currentProvider) {
        console.error('window.web3 has no currentProvider')
      }

      this.web3 = new Web3(window.web3.currentProvider)
    } else {
      console.error('web3 not found on window')
    }
  }

  async refreshAccount() {
    if (!this.web3) return

    const accounts = await this.web3.eth.getAccounts()
    this.setState({
      account: accounts.length > 0 ? accounts[0] : null
    })
  }

  async refreshBalance() {
    if (!this.web3) return
    if (!this.state.account) return

    const balance = await this.web3.eth.getBalance(this.state.account)
    this.setState({
      balance: utils.fromWei(balance)
    })
  }

  async sendTransaction () {
    this.setState({sending: true})
    const txData = {
      from: this.state.account,
      to: this.state.recipient,
      value: utils.toWei(this.state.amount, 'ether'),
      gas: 2500000
    }

    let txHash = ''
    try {
      const tx = await this.web3.eth.sendTransaction(txData)
      console.log('tx resp', tx)
      txHash = tx.transactionHash
    } catch(err) {
      console.error(err)
    }

    this.setState({
      txHash,
      sending: false
    })
  }

  async signPersonalMessage() {
    this.setState({
      personalSignature: await this.web3.eth.personal.sign(this.state.message, this.state.account)
    })
  }

  async signMessage() {
    this.setState({
      signature: await this.web3.eth.sign(this.state.message, this.state.account)
    })
  }
}

export default App
