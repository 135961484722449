let webUri = null
let apiUri = null
let rpcUri = null
let xsUri = null

let networkName = process.env.REACT_APP_NETWORK

if (!networkName || networkName === 'localhost') {
  webUri = 'http://localhost:3000'
  apiUri = 'http://localhost:4000'
  rpcUri = `https://${networkName || 'kovan'}.rpc.authereum.org`
  xsUri = 'http://localhost:3002'
} else if (networkName === 'dev') {
  webUri = 'https://dev.accounts.authereum.org'
  apiUri = 'https://dev.api.authereum.org'
  rpcUri = `https://kovan.rpc.authereum.org`
  xsUri = 'https://dev.x.authereum.org'
  networkName = 'kovan'
}

if (networkName === 'localhost') {
  networkName = 'kovan'
}

export { webUri, apiUri, rpcUri, xsUri, networkName }
